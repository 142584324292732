<template>
  <div >
    <!-- <div class="mapLeft">

        <div>
           <div class="topbt">
             <el-button size="mini" type="primary" class="searchBtn" @click="addPoint" >添加片区</el-button>
           </div>
           <el-card class="box-card lineBox" v-for="(item,index) in tableData" :key="index" @click.native="setCenterNet(item)">
              <p class="lineP">片区名称：{{item.networkName}}</p>
              <p class="lineP">管线总长度：{{(item.length/1000).toFixed(2)}}km</p>
              <p class="lineP">巡检点数量：{{item.count}}</p>
              <p class="lineP">创建人：{{item.createBy}}</p>
              <p class="lineP">创建时间：{{item.createTime}}</p>
              <p class="lineB">
                <el-button type="primary" plain size="mini" @click="editFun(item)">编辑</el-button>
                <el-button type="danger" plain size="mini" @click="delFun(item.id)">删除</el-button>
              </p>
           </el-card>
            <div class="pageBox">
              <el-pagination
                  small
                :current-page="pageParams.current"
                :background="true"
                :page-sizes="[10, 30, 50, 100]"
                :page-size="pageParams.size"
                layout="prev, pager, next"
                :total="total"
                :page-count="pagerCount"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>

        </div>
    </div> -->
    <div class="mapRight" ref="mapRightRef" id="mapRightId">
          <subMap ref="subMap" :layer-name="layerName" :rules="rules" :layer-code="layerCode" @setLocation="setLocation"/>
          <!-- <networkForm ref="formBox" :showForm="showForm" @save="save" @closePDraw="closePDraw" :addForm='addForm'/> -->
    </div>
  </div>
</template>

<script>
import { LoadMixin } from '@/common/amapLoad'
import subMap from '@/views/mapTool/subMap'
import { getNetwork ,delNetwork,networkDetail} from '@/apis/commonType'
// import networkForm from '@/views/gisMap/commonForm/networkForm'
export default {
  name: 'GisMap',
  components: {subMap },
  // networkForm
  mixins: [LoadMixin],
  data() {
    return {
      name: '片区',
      pagerCount:3,
      rules: {
        networkName: [
          { required: true, message: '请输入片区名称', trigger: 'blur' },
          { min: 0, max: 100, message: '长度在 0 到 100 个字符', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: '请输入排序', trigger: 'change' }
        ]
      },
      layerName: '',
      layerCode: '',
      pageParams: {
        current: 1,
        size: 100,
        type:1
      },
      total: 0,
      areaData:[],
      tableData:[],
      // addForm:{
      //   location:'',
      //   networkName:'',
      //   sort:''
      //   },
      // showForm:false
    }
  },
  computed: {},
  mounted() {
   this.getList()
  },
  methods: {
    setCenterNet(item){
       let p = {
          id: item.id // data.item_key
        }
      networkDetail(p).then(res => {
        let location=res.data.location
        if(location&&location.length>0){
          let polyline1 = new AMap.Polygon({
              path: location,
              strokeColor: "green",
              strokeWeight: 6,
              strokeOpacity: 0.2,
              fillOpacity: 0.4,
              fillColor: '#1791fc',
              zIndex: 50,
              bubble: true,

            })
            this.$refs.subMap.map.setFitView([polyline1],false,[200, 200, 200, 200])  
        }
       
      })

     
    },
   
     reloadList(){
       this.getList()
    },
    editFun(item){
      this.closePDraw()
      let p = {
        id: item.id // data.item_key
      }
      networkDetail(p).then(res => {
        let data={
            data:res.data,
            type:'patrol_networks'
          }
          this.$eventBus.$emit('vectorEdit', data )
      })

    },
    addPoint(){
      this.$message.success('请先在地图上点选位置，然后填写右侧表单')
       this.$refs.subMap.getLocation('polygon')
    },
    setLocation(point){
      // let path=[
      //   ...point,
      //   point[0]
      // ]
      // this.addForm.location=point
      // this.showForm=true
      let that=this

    },
    // closePDraw(){
    //  this.$refs.formBox.resetFields()
    //  this.$refs.subMap.clearLocation()
    //  this.showForm=false

    // },

    // save(){
    //      this.getList()
    //      this.closePDraw()
    //      this.$eventBus.$emit('reloadLayer')
    // },
    delFun(id){
      this.$confirm('确定要删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
      }).then(() => {
            let p = {id}
            delNetwork(p).then(res => {
              if(res.code==200){
                this.$message.success('操作成功')
                this.$eventBus.$emit('reloadLayer')
              }
              this.getList()
            })
      })
    },
    getList() {
      let p = {
        ...this.pageParams,
        ...this.searchForm
      }
      getNetwork(p).then(res => {
        this.total = res.data.total
        this.tableData = res.data.records
      })
    },
    handleSizeChange(val) {
      this.pageParams.size = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageParams.current = val
      this.getList()
    },
  }
}
</script>

<style lang="less" scoped>
.mapLeft {
  height: calc(100vh - 60px);
  width: 350px;
  background: #fff;float:left;overflow:auto;
}
.topbt{text-align: right;margin-right:20px;margin-top:10px;}
.mapRight{
  width: auto;
  margin-left:0px;position: relative;overflow: hidden;
}
.pageBox{margin:15px;text-align: center}
.lineBox{margin:15px;border:1px solid rgb(240, 240, 240);border-radius: 8px;
.lineP{margin-top:5px;}
.lineB{margin-top:10px;}
}
.searchForm{margin:15px 15px 0 5px;}
.searchBt{text-align: center;}
.formBox{position:absolute;right:0;top:100px;background: #fff;width: 300px;padding-top:10px;
.t{padding-left:10px;font-size: 16px;}
::v-deep{.el-divider--horizontal{margin-top:10px;margin-bottom:10px}
.el-date-editor.el-input, .el-date-editor.el-input__inner{width: 100%;}
}
}
</style>
